/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import Link from "next/link";
import Image from "next/image";
import logoCH1 from "../../assets/images/logoCH1.png";
import logoCHW from "../../assets/images/logoCHW.png";
import { Fragment, useEffect, useState } from "react";

import axiosconfig from "../../config/axios-config";

import Cookies from "js-cookie";

export default function Header(props) {
  const [topBar, getTop] = useState([]);
  const [reginData, setreginData] = useState([]);
  const [destinationData, setdestination] = useState([]);
  const [contactus, setcontactus] = useState(props.nav.contactus);
  const [socialMedia, setsocialMedia] = useState(props.nav.socialMedia);
  const [pagecontainerData, sePagecontainerData] = useState(
    props.nav.pagecontainerData
  );
  const [pageData, setPageData] = useState(
    props.nav.pageData.sort((a, b) => a.headerOrder - b.headerOrder)
  );

  const [CookiesUtm, setCookiesUtm] = useState({});
  const [CookiesReferal, setCookiesReferal] = useState("");

  useEffect(() => {
    if (Cookies.get("myCookieUtms")?.length > 0) {
      setCookiesUtm(JSON.parse(Cookies.get("myCookieUtms")).url);
    }
    if (Cookies.get("myCookieReferrer")?.length > 0) {
      setCookiesReferal(Cookies.get("myCookieReferrer"));
    }
  }, []);

  useEffect(() => {
    getDestinationData();
    getReginData();
    getContactusData();
    getSocialMediaData();
    getPagecontainerData();
    getPageData();
    topbarFn();
  }, []);

  const getReginData = async () => {
    const resRegion = await axiosconfig.get(
      "foundation/region/regionswithpackages/61d58047cd155a856c9d4848"
    );
    const reginData = await resRegion.data.data;
    setreginData(reginData);
  };
  const getDestinationData = async () => {
    const resDest = await axiosconfig.get("website/websitedestination/mini", {
      params: {
        PageSize: 200,
      },
    });
    const destination = await resDest.data.data;
    setdestination(destination);
  };

  const getContactusData = async () => {
    const resContact = await axiosconfig.get("website/contactus");

    const contactus = await resContact.data.data[0];
    setcontactus(contactus);
  };

  const getSocialMediaData = async () => {
    const resSocial = await axiosconfig.get("website/socialmedialink");

    const socialMedia = await resSocial.data.data;
    setsocialMedia(socialMedia);
  };
  const getPagecontainerData = async () => {
    const resCon = await axiosconfig.get("website/pagecontainer/mini", {
      params: {
        PageSize: 40,
      },
    });
    const pagecontainerData = await resCon.data.data;
    sePagecontainerData(pagecontainerData);
  };
  const getPageData = async () => {
    const resPage = await axiosconfig.get("website/pagemodule", {
      params: {
        PageSize: 40,
      },
    });

    const pageData = await resPage.data.data;
    setPageData(pageData);
    console.log(pageData);
  };

  const [toggleBtn, setToggle] = useState(false);

  const topbarFn = async () => {
    const res = await axiosconfig.get("website/topbar");

    const alldata = await res.data;
    getTop(alldata.data);
    console.log(alldata);
  };

  return (
    <header id="masthead" className="site-header header-primary sticky-top">
      <div className="row">
        {topBar.map((topData) => {
          return (
            <Fragment key={topData.id}>
              {topData.isActive === true && (
                <div
                  className={`top-header col-12 order-${topBar.order}`}
                  style={{ backgroundColor: "#00000033" }}
                  key={topData.id}
                >
                  <div className="container">
                    <div className="row ">
                      <div className="col-12 text-center d-none d-lg-block">
                        <div
                          className="header-contact-info"
                          style={{ fontSize: "14px" }}
                        >
                          <span className="">{topData.title1} </span>
                          <i className="fa fa-dot-circle mx-1"></i>
                          <span className="">{topData.title2} </span>
                          {topData.isExternalLink === true ? (
                            <Link
                              href={topData.externalLink}
                              target={topData.target}
                              rel="noreferrer"
                              style={{ color: "white" }}
                            >
                              {topData.linkText}
                            </Link>
                          ) : (
                            <Link href={`/${topData.pageModuleId}`}>
                              <span style={{ color: "white" }}>
                                {topData.linkText}
                              </span>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
      <div className="top-header ">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 d-none d-lg-block">
              <div className="header-contact-info">
                <ul>
                  <li>
                    <Link href={`tel:${contactus.mainPhone}`}>
                      <>
                        {" "}
                        <i className="fa fa-phone"></i> {contactus.mainPhone}
                      </>
                    </Link>
                  </li>
                  <li>
                    <Link href="mailto:info@centralholidays.com">
                      <>
                        <i className="fas fa-envelope"></i> {contactus.email1}
                      </>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 d-flex justify-content-lg-end justify-content-between">
              <div className="header-social social-links">
                <ul className="row">
                  {socialMedia.map((social) => {
                    return (
                      <>
                        {social.isActive == true && (
                          <li
                            key={social.id}
                            className={`order-${social.order}`}
                          >
                            <Link
                              href={social.socialMediaURL}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Image
                                loading="lazy"
                                width="25px"
                                height="25"
                                src={social.iconURL ? social.iconURL : ""}
                              />
                            </Link>
                          </li>
                        )}
                      </>
                    );
                  })}
                </ul>
              </div>
              <div className="header-search-icon"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-header d-flex ">
        <div className="container d-flex justify-content-around align-items-center ">
          <div className="site-identity">
            <h1 className="site-title">
              <Link href="/">
                <>
                  <span className="white-logo">
                    <Image loading="lazy" alt="" src={logoCHW} />
                  </span>
                  <span className="black-logo">
                    <Image loading="lazy" alt="" src={logoCH1} />
                  </span>
                </>
              </Link>
            </h1>
          </div>
          <div className="main-navigation d-none d-lg-block">
            <nav id="navigation" className="navigation">
              <ul className="row">
                <li className="menu-item-has- ">
                  <Link href="/"> Home</Link>{" "}
                </li>
                <li className="menu-item-has-children ">
                  <a>Destinations</a>
                  <ul>
                    {destinationData.map((dest) => {
                      return (
                        <>
                          {reginData.map((region) => {
                            return (
                              <>
                                {region.countriesId.map((id, i) => {
                                  return (
                                    <>
                                      {dest.destinationId === id &&
                                        dest.isNavShown === true && (
                                          <li
                                            key={dest.id}
                                            className="nav-item"
                                          >
                                            <Link
                                              href={`/destinations/${region.countriesNames[
                                                i
                                              ]
                                                .replace(
                                                  /[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi,
                                                  ""
                                                )
                                                .replace(/\s+/g, " ")
                                                .replace(" ", "-")
                                                .split(" ")
                                                .join("-")}`}
                                            >
                                              <a className="nav-link">
                                                {region.countriesNames[i]}
                                              </a>
                                            </Link>
                                          </li>
                                        )}
                                    </>
                                  );
                                })}
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                    {reginData.map((region) => {
                      return (
                        <li
                          key={region.id}
                          className="nav-item menu-item-has-children"
                        >
                          <Link
                            href={`/region/${region.name
                              .replace(
                                /[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi,
                                ""
                              )
                              .replace(/\s+/g, " ")
                              .replace(" ", "-")
                              .split(" ")
                              .join("-")}`}
                          >
                            <a className="nav-link">{region.name}</a>
                          </Link>
                          <ul className="dropdown-menu">
                            {region.countriesId.map((id, i) => {
                              return (
                                <li key={id} className="nav-item">
                                  {" "}
                                  <Link
                                    href={`/destinations/${region.countriesNames[
                                      i
                                    ]
                                      .replace(
                                        /[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi,
                                        ""
                                      )
                                      .replace(/\s+/g, " ")
                                      .replace(" ", "-")
                                      .split(" ")
                                      .join("-")}`}
                                  >
                                    <a className="nav-link">
                                      {region.countriesNames[i]}
                                    </a>
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      );
                    })}
                  </ul>
                </li>

                {pageData.map((page) => {
                  return (
                    <>
                      {page.isHeaderPage == true && (
                        <li
                          key={page.id}
                          className={`menu-item-has- order-${page.headerOrder}`}
                        >
                          {page.isActive == true ? (
                            pagecontainerData.map((container) => {
                              if (
                                page.id === container.pageModuleId &&
                                container.pageSubModuleId.length < 1
                              ) {
                                if (container.isExtrnalLink === true) {
                                  return (
                                    <>
                                      {CookiesUtm?.length > 0 ||
                                      CookiesReferal?.length > 0 ? (
                                        <Link
                                          rel="noreferrer"
                                          href={`${container.externalLinkUrl}?UTMSCode=${CookiesUtm}&referrerUrl=${CookiesReferal}`}
                                        >
                                          <a className="nav-link">
                                            {page.name}
                                          </a>
                                        </Link>
                                      ) : (
                                        <Link
                                          rel="noreferrer"
                                          href={`${container.externalLinkUrl}`}
                                        >
                                          <a className="nav-link">
                                            {page.name}
                                          </a>
                                        </Link>
                                      )}
                                    </>
                                  );
                                } else if (container.isPdfUrl === true) {
                                  return (
                                    <>
                                      <Link
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`${container.pdfUrl}`}
                                      >
                                        <a className="nav-link">{page.name}</a>
                                      </Link>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <Link
                                        href={`/${
                                          container.pageSEO?.pageURL?.length > 0
                                            ? container.pageSEO.pageURL
                                            : page.name
                                                .replace(
                                                  /[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi,
                                                  ""
                                                )
                                                .replace(/\s+/g, " ")
                                                .replace(" ", "-")
                                                .split(" ")
                                                .join("-")
                                        }`}
                                      >
                                        <a className="nav-link">{page.name}</a>
                                      </Link>
                                    </>
                                  );
                                }
                              }
                            })
                          ) : (
                            <a
                              style={{ cursor: "pointer" }}
                              className="nav-link"
                            >
                              {page.name}
                            </a>
                          )}
                        </li>
                      )}
                    </>
                  );
                })}
              </ul>
            </nav>
          </div>
          <div className="mobile-menu-container mb-1 d-flex d-lg-none">
            <div onClick={() => setToggle(!toggleBtn)} className="btnMenu">
              <i
                className="fa-solid fa-bars mx-2 "
                style={{ color: "#df1b23" }}
              ></i>
              <span style={{ color: "#df1b23" }}>Menu</span>
            </div>
          </div>
        </div>
      </div>

      {toggleBtn == true && (
        <div className="main-navigation resp-menu d-block d-lg-none">
          <nav id="navigation" className="navigation">
            <ul className="row">
              <li className="menu-item-has- order-1">
                <Link href="/">Home</Link>{" "}
              </li>

              <li className="menu-item-has-children order-2">
                <a>Destinations</a>
                <ul>
                  {destinationData.map((dest) => {
                    return (
                      <>
                        {reginData.map((region) => {
                          return (
                            <>
                              {region.countriesId.map((id, i) => {
                                return (
                                  <>
                                    {dest.destinationId === id &&
                                      dest.isNavShown === true && (
                                        <li key={dest.id} className="nav-item">
                                          <Link
                                            href={`/destinations/${region.countriesNames[
                                              i
                                            ]
                                              .replace(
                                                /[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi,
                                                ""
                                              )
                                              .replace(/\s+/g, " ")
                                              .replace(" ", "-")
                                              .split(" ")
                                              .join("-")}`}
                                          >
                                            <a className="nav-link">
                                              {region.countriesNames[i]}
                                            </a>
                                          </Link>
                                        </li>
                                      )}
                                  </>
                                );
                              })}
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                  {reginData.map((region) => {
                    return (
                      <li
                        key={region.id}
                        className="nav-item menu-item-has-children"
                      >
                        <Link
                          href={`/region/${region.name
                            .replace(
                              /[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi,
                              ""
                            )
                            .replace(/\s+/g, " ")
                            .replace(" ", "-")
                            .split(" ")
                            .join("-")}`}
                        >
                          <a className="nav-link">{region.name}</a>
                        </Link>
                        <ul className="dropdown-menu">
                          {region.countriesId.map((id, i) => {
                            return (
                              <li key={id} className="nav-item">
                                {" "}
                                <Link
                                  href={`/destinations/${region.countriesNames[
                                    i
                                  ]
                                    .replace(
                                      /[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi,
                                      ""
                                    )
                                    .replace(/\s+/g, " ")
                                    .replace(" ", "-")
                                    .split(" ")
                                    .join("-")}`}
                                >
                                  <a className="nav-link">
                                    {region.countriesNames[i]}
                                  </a>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  })}
                </ul>
              </li>
              {pageData.map((page) => {
                return (
                  <>
                    {page.isHeaderPage == true && (
                      <li
                        key={page.id}
                        className={`menu-item-has- order-${page.headerOrder}`}
                      >
                        {page.isActive == true ? (
                          pagecontainerData.map((container) => {
                            if (
                              page.id === container.pageModuleId &&
                              container.pageSubModuleId.length < 1
                            ) {
                              if (container.isExtrnalLink === true) {
                                return (
                                  <>
                                    <Link
                                      rel="noreferrer"
                                      href={`${container.externalLinkUrl}`}
                                    >
                                      <a className="nav-link">{page.name}</a>
                                    </Link>
                                  </>
                                );
                              } else if (container.isPdfUrl === true) {
                                return (
                                  <>
                                    <Link
                                      target="_blank"
                                      rel="noreferrer"
                                      href={`${container.pdfUrl}`}
                                    >
                                      <a className="nav-link">{page.name}</a>
                                    </Link>
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    <Link
                                      href={`/${
                                        container.pageSEO?.pageURL?.length > 0
                                          ? container.pageSEO.pageURL
                                          : page.name
                                              .replace(
                                                /[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi,
                                                ""
                                              )
                                              .replace(/\s+/g, " ")
                                              .replace(" ", "-")
                                              .split(" ")
                                              .join("-")
                                      }`}
                                    >
                                      <a className="nav-link">{page.name}</a>
                                    </Link>
                                  </>
                                );
                              }
                            }
                          })
                        ) : (
                          <a style={{ cursor: "pointer" }} className="nav-link">
                            {page.name}
                          </a>
                        )}
                      </li>
                    )}
                  </>
                );
              })}
            </ul>
          </nav>
        </div>
      )}
    </header>
  );
}
