import axios from "axios";

export const axiosPublic = axios.create({
  baseURL: "https://chtg-api.elastic.travel/api/",
  headers: {
    "Content-Type": "application/json",
  },
});

export const refreshTokenFn = async () => {
  try {
    const response = await axiosPublic.post("admin/user/website-login");
    const session = await response.data.data;
    if (typeof window !== "undefined") {
      sessionStorage.setItem("Token", session.token);
      sessionStorage.setItem("CompanyId", session.companyId);
    }
    return session;
  } catch (error) {}
};
