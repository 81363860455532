import Header from "../components/Header";
import Footer from "../components/Footer";
import ScrolltoTop from "../components/scrollTop";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../styles/custom.css";
import "../styles/style.css";
import { useEffect, useState } from "react";
import HomeLoading from "../components/layout/Home.loading";
import axiosconfig from "../config/axios-config";
import Cookies from "js-cookie";

function MyApp({ Component, pageProps, navigationProps }) {
  const [homeLoading, setHomeLoading] = useState(true);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.search.includes("utm")) {
        const params = new URLSearchParams(window.location.search);
        const UtmObject = {
          url: window.location.href,
          utm_source: params.get("utm_source"),
          utm_campaign: params.get("utm_campaign"),
          utm_medium: params.get("utm_medium"),
          utm_content: params.get("utm_content"),
        };
        const jsonUTMObject = JSON.stringify(UtmObject);
        Cookies.set("myCookieUtms", jsonUTMObject, { expires: 48 });
      }

      if (
        window.document.referrer &&
        !window.document.referrer.includes(window.location.origin)
      ) {
        Cookies.set("myCookieReferrer", window.document.referrer, {
          expires: 48,
        });
      }
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setHomeLoading(false);
    }, 200);
  }, []);

  return (
    <>
      <Header nav={navigationProps} />
      <HomeLoading loading={homeLoading} />
      <Component {...pageProps} />
      <Footer nav={navigationProps} />
      <ScrolltoTop />
    </>
  );
}
let navigationPropsCache;
MyApp.getInitialProps = async () => {
  if (navigationPropsCache) {
    return { navigationProps: navigationPropsCache };
  }

  const resPage = await axiosconfig.get("website/pagemodule", {
    params: {
      PageSize: 40,
    },
  });

  const pageData = await resPage.data.data;

  const resCon = await axiosconfig.get("website/pagecontainer/mini", {
    params: {
      PageSize: 40,
    },
  });
  const pagecontainerData = await resCon.data.data;

  const resContact = await axiosconfig.get("website/contactus");

  const contactus = await resContact.data.data[0];

  const resSocial = await axiosconfig.get("website/socialmedialink");

  const socialMedia = await resSocial.data.data;

  const navigationProps = {
    pageData,
    pagecontainerData,
    contactus,
    socialMedia,
  };
  navigationPropsCache = navigationProps;

  return { navigationProps };
};

export default MyApp;
