/* eslint-disable @next/next/no-img-element */
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";

import axiosconfig from "../../config/axios-config";

export default function Footer(props) {
  const [footerData, getFooterData] = useState([]);
  const [pagecontainerData, sePagecontainerData] = useState(
    props.nav.pagecontainerData
  );
  const [pageData, setPageData] = useState(props.nav.pageData);

  useEffect(() => {
    footerDataFn();
    getPagecontainerData();
    getPageData();
  }, []);

  async function getPagecontainerData() {
    const resCon = await axiosconfig.get("website/pagecontainer/mini", {
      params: {
        PageSize: 40,
      },
    });
    const pagecontainerData = await resCon.data.data;
    sePagecontainerData(pagecontainerData);
  }
  async function getPageData() {
    const resPage = await axiosconfig.get("website/pagemodule", {
      params: {
        PageSize: 40,
      },
    });

    const pageData = await resPage.data.data;
    setPageData(pageData);
  }

  async function footerDataFn() {
    const res = await axiosconfig.get("website/footer");

    const alldata = await res.data;

    getFooterData(alldata.data[0]);
  }

  var PageShow = [];
  pageData.forEach((page) => {
    if (page.isFooterPage == true) {
      PageShow.push(page);
    }
  });

  return (
    <footer id="colophon" className="site-footer footer-primary">
      <div className="top-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <aside className="widget widget_text">
                <h3 className="widget-title">{footerData.companyName}</h3>
                <div className="textwidget widget-text">
                  <p
                    dangerouslySetInnerHTML={{ __html: footerData.description }}
                  ></p>{" "}
                  <br />
                  <a>
                    <img alt="footer-Logo" src={footerData.logoURL} />
                  </a>
                </div>
              </aside>
            </div>

            <div className="col-lg-3 col-md-6">
              <aside className="widget widget_recent_post">
                <h3 className="widget-title">Quick Links</h3>
                <ul className="row">
                  <li className="col-12 order-1">
                    <Link href="/">- Home</Link>{" "}
                  </li>
                  <div style={{ clear: "both" }}></div>
                  {PageShow.slice(0, PageShow.length / 2).map((page) => {
                    return (
                      <>
                        <li
                          className={`col-12 order-${page.order}`}
                          key={page.id}
                        >
                          {page.isActive == true ? (
                            pagecontainerData.map((container) => {
                              if (
                                page.id === container.pageModuleId &&
                                container.pageSubModuleId.length < 1
                              ) {
                                if (container.isExtrnalLink == true) {
                                  return (
                                    <>
                                      -
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`${container.externalLinkUrl}`}
                                      >
                                        {page.name}
                                      </a>
                                    </>
                                  );
                                } else if (container.isPdfUrl == true) {
                                  return (
                                    <>
                                      -{" "}
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`${container.pdfUrl}`}
                                      >
                                        {page.name}
                                      </a>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <Link
                                        href={`/${
                                          container.pageSEO?.pageURL?.length > 0
                                            ? container.pageSEO.pageURL
                                            : page.name
                                                .replace(
                                                  /[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi,
                                                  ""
                                                )
                                                .replace(/\s+/g, " ")
                                                .replace(" ", "-")
                                                .split(" ")
                                                .join("-")
                                        }`}
                                      >
                                        <a>- {page.name}</a>
                                      </Link>
                                    </>
                                  );
                                }
                              }
                            })
                          ) : (
                            <a style={{ cursor: "pointer" }}>- {page.name}</a>
                          )}{" "}
                        </li>
                        <div style={{ clear: "both" }}></div>
                      </>
                    );
                  })}
                </ul>
              </aside>
            </div>

            <div className="col-lg-3 col-md-6">
              <aside className="widget widget_recent_post">
                <h3 className="widget-title">Quick Links</h3>
                <div className="textwidget widget-text">
                  <ul className="row">
                    {PageShow.slice(PageShow.length / 2).map((page) => {
                      return (
                        <>
                          <li
                            className={`col-12 order-${page.order}`}
                            key={page.id}
                          >
                            {page.isActive == true ? (
                              pagecontainerData.map((container) => {
                                if (
                                  page.id === container.pageModuleId &&
                                  container.pageSubModuleId.length < 1
                                ) {
                                  if (container.isExtrnalLink == true) {
                                    return (
                                      <>
                                        -{" "}
                                        <a
                                          rel="noreferrer"
                                          href={`${container.externalLinkUrl}`}
                                        >
                                          {page.name}
                                        </a>
                                      </>
                                    );
                                  } else if (container.isPdfUrl == true) {
                                    return (
                                      <>
                                        -{" "}
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={`${container.pdfUrl}`}
                                        >
                                          {page.name}
                                        </a>
                                      </>
                                    );
                                  } else {
                                    return (
                                      <>
                                        <Link
                                          href={`/${
                                            container.pageSEO?.pageURL?.length >
                                            0
                                              ? container.pageSEO.pageURL
                                              : page.name
                                                  .replace(
                                                    /[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi,
                                                    ""
                                                  )
                                                  .replace(/\s+/g, " ")
                                                  .replace(" ", "-")
                                                  .split(" ")
                                                  .join("-")
                                          }`}
                                        >
                                          <a>- {page.name}</a>
                                        </Link>
                                      </>
                                    );
                                  }
                                }
                              })
                            ) : (
                              <a style={{ cursor: "pointer" }}>- {page.name}</a>
                            )}{" "}
                          </li>
                          <div style={{ clear: "both" }}></div>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
      <div className="buttom-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="footer-menu">
                <ul>
                  <li>
                    {" "}
                    <a
                      href="https://booking.centralholidays.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Agent Portal{" "}
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a
                      href="https://portal.centralholidays.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Passenger Portal
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a
                      href="https://supplier.centralholidays.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Supplier Portal
                    </a>{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 text-center"></div>
            <div className="col-md-5">
              <div className="copy-right text-right">
                © Copyright Central Holidays {new Date().getFullYear()}. All
                Rights Reserved
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
