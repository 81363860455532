import Image from "next/image";
import classes from "../../styles/home.loading.module.css";

const HomeLoading = (props) => {
  return (
    <div
      className={classes.home_loading_container}
      style={{
        opacity: !props.loading ? 0 : 1,
        zIndex: !props.loading ? -1 : 2000,
      }}
    >
      <span className={classes.m_right_2}>
        <Image
          layout="responsive"
          loading="lazy"
          width={35}
          height={42}
          src="/central-logo-sm.png"
          alt="Central Holidays"
        />
      </span>
      <div className={classes.obj}></div>
      <div className={classes.obj}></div>
      <div className={classes.obj}></div>
      <div className={classes.obj}></div>
      <div className={classes.obj}></div>
      <div className={classes.obj}></div>
      <div className={classes.obj}></div>
      <div className={classes.obj}></div>
    </div>
  );
};

export default HomeLoading;
