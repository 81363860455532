import { useEffect, useState } from "react";

export default function ScrolltoTop() {

    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > 300) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      });
    }, []);
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };
  

    return(
        <>
        {showButton && (
        <a style={{display: 'visible' ? 'inline' : 'none'}} onClick={scrollToTop} className="to-top-icon">
            <i className="fas fa-chevron-up"></i>
        </a>
        )}
        </>
    )
}